import { Hit } from '@algolia/client-search';
import { AlgoliaArticle, BlogpostArticle, DocumentationArticle, ZendeskArticle } from '@/types/search/documents';
// eslint-disable-next-line import/no-cycle
import { DocumentType } from '@/types/search/search';

export interface SearchResult {
  url: string;
  title: string;
  snippet: string;
  category: DocumentType;
  tags: string[];
}

const getSearchResultsFromBlogArticle = ({
  path,
  category: { name: tag },
  _highlightResult,
  _snippetResult,
}: Hit<BlogpostArticle>): SearchResult => ({
  url: `${path}`,
  title: _highlightResult?.title?.value ?? '',
  snippet: _snippetResult?.body?.value || _snippetResult?.description?.value || '',
  category: DocumentType.Blogpost,
  tags: [tag],
});

// Removing the anchor tag from the path until we find the root cause of the issue with the last indexed section of the page.
const getSearchResultsFromDocumentationArticle = ({
  path,
  product: tag,
  _highlightResult,
  _snippetResult,
}: Hit<DocumentationArticle>): SearchResult => ({
  url: `${path}`,
  title: _highlightResult?.title?.value ?? '',
  snippet: _snippetResult?.body?.value || _snippetResult?.description?.value || '',
  category: DocumentType.Documentation,
  tags: [tag],
});

const getSearchResultsFromZendeskArticle = ({
  id,
  section: { full_path: tag },
  _highlightResult,
  _snippetResult,
}: Hit<ZendeskArticle>): SearchResult => ({
  url: `https://help.nexmo.com/hc/en-us/articles/${id}`,
  title: _highlightResult?.title?.value ?? '',
  snippet: _snippetResult?.body_safe?.value ?? '',
  category: DocumentType.ZendeskArticle,
  tags: [tag],
});

export function getSearchResults(type: DocumentType, hit: Hit<AlgoliaArticle>): SearchResult {
  switch (type) {
    case DocumentType.Blogpost:
      return getSearchResultsFromBlogArticle(hit as Hit<BlogpostArticle>);
    case DocumentType.Documentation:
      return getSearchResultsFromDocumentationArticle(hit as Hit<DocumentationArticle>);
    default:
      return getSearchResultsFromZendeskArticle(hit as Hit<ZendeskArticle>);
  }
}
