import { Hit, MultipleQueriesQuery, SearchResponse } from '@algolia/client-search';
import { getAlgoliaClient, useAlgoliaIndexNames } from '@/types/search/algolia';
// eslint-disable-next-line import/no-cycle
import { getSearchResults, type SearchResult } from '@/types/search/results';
import { AlgoliaArticle } from '@/types/search/documents';

const AlgoliaIndexes = useAlgoliaIndexNames();
const AlgoliaClient = getAlgoliaClient();

const CommonQueryParams = {
  analytics: true,
  distinct: true,
  getRankingInfo: true,
  snippetEllipsisText: '…',
};

export enum DocumentType {
  Blogpost,
  Documentation,
  ZendeskArticle,
}

const IndexNameForDocumentType = {
  [DocumentType.Blogpost]: AlgoliaIndexes.blogposts,
  [DocumentType.Documentation]: AlgoliaIndexes.documentation,
  [DocumentType.ZendeskArticle]: AlgoliaIndexes.zendeskArticles,
};

const getParamsForType = (type: DocumentType, snippetSize: number) => {
  switch (type) {
    case DocumentType.ZendeskArticle:
      return {
        attributesToHighlight: ['title'],
        attributesToSnippet: [`body_safe:${snippetSize}`],
      };
    default:
      return {
        attributesToHighlight: ['title', 'description', 'heading'],
        attributesToSnippet: [`body:${snippetSize}`, `description:${snippetSize}`],
      };
  }
};

export const getParametersForDocumentType = (
  type: DocumentType,
  query: string,
  page: number,
  hitsPerPage = 10,
  snippetSize = 60
): MultipleQueriesQuery => ({
  indexName: IndexNameForDocumentType[type],
  query,
  params: {
    page,
    hitsPerPage,
    ...CommonQueryParams,
    ...getParamsForType(type, snippetSize),
  },
});

async function algoliaClientSearch(parameters: MultipleQueriesQuery[]): Promise<SearchResponse[]> {
  const result = await AlgoliaClient.search(parameters);
  return result.results;
}

export interface GenericSearchResults {
  hits: SearchResult[];
  totalHits: number;
  pages: number;
}

export interface QuickSearchResults {
  docs: SearchResult[];
  blog: SearchResult[];
  zendesk: SearchResult[];
}

const convertHitsToSearchResults = (documentType: DocumentType, hits: Hit<AlgoliaArticle>[]) =>
  hits.map(hit => getSearchResults(documentType, hit as Hit<AlgoliaArticle>));

export async function getSearchResultsForQuery(
  documentType: DocumentType,
  query: string,
  page: number
): Promise<GenericSearchResults> {
  const results = await algoliaClientSearch([getParametersForDocumentType(documentType, query, page)]);
  const { hits, nbHits: totalHits, nbPages: pages } = results[0];
  return { hits: convertHitsToSearchResults(documentType, hits as Hit<AlgoliaArticle>[]), pages, totalHits };
}

export async function getQuickResultsForQuery(query: string): Promise<QuickSearchResults> {
  const [docs, blog, zendesk] = await algoliaClientSearch([
    getParametersForDocumentType(DocumentType.Documentation, query, 0, 3, 10),
    getParametersForDocumentType(DocumentType.Blogpost, query, 0, 3, 10),
    getParametersForDocumentType(DocumentType.ZendeskArticle, query, 0, 3, 10),
  ]);
  return {
    docs: convertHitsToSearchResults(DocumentType.Documentation, docs.hits as Hit<AlgoliaArticle>[]),
    blog: convertHitsToSearchResults(DocumentType.Blogpost, blog.hits as Hit<AlgoliaArticle>[]),
    zendesk: convertHitsToSearchResults(DocumentType.ZendeskArticle, zendesk.hits as Hit<AlgoliaArticle>[]),
  };
}
