import { readMetaTag } from '@/utils/readMetaTag';
import algoliasearch, { SearchClient } from 'algoliasearch';

export const getAlgoliaClient = (): SearchClient => {
  const AlgoliaAppId = readMetaTag('algoliaAppId');
  const AlgoliaApiKey = readMetaTag('algoliaSearchApiKey');
  return algoliasearch(AlgoliaAppId, AlgoliaApiKey);
};

export const useAlgoliaIndexNames = () => {
  // Only en is supported for now
  const AlgoliaLocale = 'en';
  const AlgoliaEnv = readMetaTag('algoliaEnv');
  return {
    blogposts: `${AlgoliaEnv}_bifrost_${AlgoliaLocale}_blogposts_sb`,
    documentation: `${AlgoliaEnv}_bifrost_${AlgoliaLocale}_documentation`,
    zendeskArticles: 'zendesk_nexmo_articles',
  };
};
