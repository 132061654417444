// eslint-disable-next-line import/prefer-default-export
export const readMetaTag = (name: string) => {
  if (typeof document !== 'undefined') {
    const tag = document.querySelector(`meta[name="${name}"]`);
    if (!(tag instanceof HTMLMetaElement)) {
      throw new Error(`Meta tag "${name}" not found.`);
    }
    return tag.content;
  }
  return null;
};
