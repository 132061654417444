<template>
  <component :is="componentType" v-bind="linkProps" class="link">
    <div class="item">
      <div :class="[query ? (isMobileSearchPage ? 'subtitle-2' : 'query-title-font') : 'search-popup-title-font']">
        <div class="title" v-html="hit.title" />
      </div>
      <div class="badge">
        <VBadge
          v-for="tag in hit.tags"
          :key="tag"
          class="docs"
          :text="tag"
          appearance="subtle"
          shape="pill"
          connotation="information"
        />
      </div>
      <div
        :class="[
          'snippet',
          query ? (isMobileSearchPage ? 'small-snippet-font' : 'query-snippet-font') : 'small-snippet-font',
        ]"
        v-html="hit.snippet"
      />
    </div>
  </component>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, type PropType, ref } from 'vue';
import { isAbsoluteUrl } from '@/utils/url';
import { VBadge } from '@vonage/vivid-vue';
import { type SearchResult } from '@/types/search/results';

const props = defineProps({
  hit: { type: Object as PropType<SearchResult>, required: true },
  query: { type: String, required: false },
});
const componentType = computed(() => (isAbsoluteUrl(props.hit.url) ? 'a' : 'router-link'));
const linkProps = computed(() => ({ [isAbsoluteUrl(props.hit.url) ? 'href' : 'to']: props.hit.url }));
const windowSize = ref(window.innerWidth);
const isMobileSearchPage = computed(() => window.location.href.includes('/search') && windowSize.value < 425);

onMounted(() => {
  window.addEventListener('resize', () => {
    windowSize.value = window.innerWidth;
  });
});

onUnmounted(() => {
  window.removeEventListener('resize', () => {
    windowSize.value = window.innerWidth;
  });
});
</script>

<style scoped lang="scss">
@import '../../styles/media';

.link {
  text-decoration: unset;
  color: unset;
}

.item {
  padding: 20px 24px;
  border-radius: 8px;

  &:hover {
    background-color: #f2f2f2;
    cursor: pointer;
  }
}

.title {
  padding-bottom: 8px;
  font-weight: normal;
  color: var(--dp-color-cta-500);

  & :deep(em) {
    font-weight: 600;
    font-style: unset;
  }
}

.snippet {
  padding-top: 4px;
  overflow-wrap: break-word;
  color: rgb(13, 13, 13) !important;
  font: var(--vvd-typography-base);

  & :deep(em) {
    color: var(--dp-color-cta-500);
    font-weight: 600;
    font-style: unset;
  }
}

@media screen and (max-width: $mobile-large) {
  .item {
    padding: 20px 24px;
  }
}

@media screen and (min-width: $desktop-large) {
  .title {
    font: var(--vvd-typography-heading-4);
  }
  .query-snippet-font {
    font: var(--vvd-typography-base);
  }
}
.small-snippet-font {
  font: var(--vvd-typography-base-condensed);
}
.query-title-font {
  font: var(--vvd-typography-heading-4);
}
.search-popup-title-font {
  font: var(--vvd-typography-base-extended);
}
.subtitle-2 {
  font: var(--vvd-typography-base);
}
</style>
